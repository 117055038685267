<!-- 走访慰问表格 -->
<template>
  <div>
    <ax-table
      ref="tableBox"
      :columns="columns"
      :toolActions="toolActions"
      :show-search="true"
      :searchForm="searchForm"
      @delect="del"
      @add="add"
      :showToolBar="true"
      @action-column-click="actionColumnClick"
      @pop-confirm="popConfirm"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onChange: onSelectChange,
      }"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
       :scroll="{ y: '55vh', x: '80vw' }"
    >
      <div slot="age" slot-scope="{ record }" class="operName">
        {{
          new Date().getFullYear() - parseInt(record.identityCard.substr(6, 4))
        }}
      </div>
      <div slot="cellName" slot-scope="{ record }" class="operName">
        {{ record.cellName ? record.cellName : "--" }}
      </div>
      <!-- 风险等级 -->
      <div slot="riskLevel_dictText" slot-scope="{ record }" class="operName">
        <a-button
          :class="{
            grey: record.riskLevel === '3',
            red: record.riskLevel === '1',
            yellow: record.riskLevel === '2',
          }"
          >{{ record.riskLevel_dictText }}</a-button
        >
      </div>
    </ax-table>
    <!-- 新增重点维稳信访人员信息弹窗 -->
    <add-interview ref="interviewInfo" @refsh="refsh"> </add-interview>
    <watch-interview ref="interviewInfoDetail"></watch-interview>
  </div>
</template>

<script>
import addInterview from "./addInterview.vue";
import watchInterview from "./watchInterview.vue";
import moment from "moment";
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
const searchForm = [
  {
    label: "关键字",
    type: "input",
    model: "searchText",
    options: { placeholder: "姓名/身份证号/联系电话" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所在小区",
    type: "select",
    model: "cellName",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "记录人",
    type: "",
    model: "updateBy",
    options: { placeholder: "请输入" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "走访开始时间",
    type: "datePicker",
    model: "interviewTimeStart",
    options: { placeholder: "请选择",disabledDate: (current) => current > moment().endOf("today"),showTime: true  },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "走访结束时间",
    type: "datePicker",
    model: "interviewTimeEnd",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";
export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addInterview, watchInterview },
  data() {
    //这里存放数据
    return {
      api,
      options: [],
      currentAreaCode: "",
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "姓名",
            dataIndex: "residentName",
            ellipsis: true,
            width: 70,
          },
          {
            title: "性别",
            dataIndex: "gender",
            ellipsis: false,
            width: 70,
          },
          {
            title: "身份证号",
            dataIndex: "identityCard",
            ellipsis: true,
            width: 180,
          },
          {
            title: "年龄",
            dataIndex: "age",
            ellipsis: false,
            width: 70,
          },
          {
            title: "所在小区",
            dataIndex: "cellName",
            ellipsis: false,
            width: 150,
          },
          {
            title: "现住区域",
            dataIndex: "currentArea",
            ellipsis: true,
            width: 180,
          },
          {
            title: "联系电话",
            dataIndex: "phone",
            ellipsis: true,
            width: 130,
          },
          {
            title: "人员类型",
            dataIndex: "type_dictText",
            ellipsis: true,
            width: 100,
          },
          {
            title: "走访时间",
            dataIndex: "interviewTime",
            ellipsis: false,
            width: 120,
          },
          {
            title: "现实状态",
            dataIndex: "actualState_dictText",
            ellipsis: true,
            width: 120,
          },
          {
            title: "记录人",
            dataIndex: "updateBy",
            ellipsis: true,
            width: 100,
          },
        ],
        false, //去掉序号
        {
          width: 220,
          fixed:'right',
          // 操作部分
          actions: [
            {
              props: (record) => {
                return {
                  text: "编辑",
                  name: "edit",
                  type: "#556BFF",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "详情",
                  name: "watch",
                  type: "#E6A23C",
                  link: true,
                  popDisabled: true,
                  // disabled: record.isAbandon === "1",
                };
              },
            },
            {
              props: (record) => {
                return {
                  text: "删除",
                  name: "del",
                  type: "#F95A5A",
                  link: true,
                  popDisabled: false,
                  title: "是否删除当前内容?",
                  // disabled: record.isAbandon === "1",
                };
              },
            },
          ],
        }
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 100,
      }),
      toolActions: [
        { name: "add", text: "新增" },
        // { name: "inports", text: "导入", type: "#67C23A" },
        // { name: "export", text: "导出", type: "#67C23A" },
        { name: "delect", text: "批量删除", type: "#F95A5A" },
      ],
      selectedRowKeys: [],
      currentAreaCode1: [],
      dataSourceApis: api.list,
      dataSourceParams: {},
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    // 表格操作
    async actionColumnClick(args) {
      const {
        btn: { name },
        record,
      } = args;
      switch (name) {
        // 编辑
        case "edit":
          record.title = "编辑走访信息";
          this.$refs.interviewInfo.openModal(record);
          break;
        // 详情
        case "watch":
          record.title = "走访记录";
          this.$refs.interviewInfoDetail.openModal(record);
          break;
        // 详情
        case "del":
          break;
      }
    },
    // 根据id删除
    async popConfirm({ record }) {
      const res = await api.deleteInterviewInfoById(record.id);
      if (res.status === 200) {
        this.$message.success("删除成功");
        this.$refs.tableBox.getDataSource();
      }
    },
    refsh() {
      this.$refs.tableBox.getDataSource();
    },
    add() {
      this.$refs.interviewInfo.openModal({ title: "添加走访" });
    },
    // 批量删除
    del() {
      var that = this;
      if (that.selectedRowKeys.length === 0) {
        this.$message.warning("请勾选要删除的数据！");
      } else {
        this.$confirm({
          title: "是否删除",
          content: "确认是否删除当前选中内容",
          cancelText: "取消",
          okText: "确定",
          onOk: function () {
            api
              .deleteInterviewInfoByIds(that.selectedRowKeys.join(","))
              .then((res) => {
                that.$message.success("删除成功");
                that.$refs.tableBox.getDataSource();
              });
          },
        });
      }
    },
    onSelectChange(selectedRowKeys, row) {
      this.selectedRowKeys = selectedRowKeys;
    },
    // 获取字典
    async getDictionsary() {
      // 所在小区
      await api.getGridManagement().then((res) => {
        const options = res.data.map((res) => {
          return { label: res.name, value: res.name };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "cellName",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.getDictionsary();
    this.$refs.tableBox.$children[0].$refs.searchForm.setOptions([interviewTimeStart], 'disabledDate', val => { 
      // return startValue.valueOf() > new Date().valueOf();
              return this.$ax.dateUtil.getDisabledDate(val, this.formBuilder.formValues[disabledDate], equal ? '<' : '<=', format)
            })
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang='less' scoped>
.grey {
  background-color: #e6e9ee;
  color: #2d3138;
  border: #e6e9ee;
  border-radius: 4px;
}
.red {
  background-color: #fff1f0;
  color: #cf1322;
  border-radius: 4px;
  border: #fff1f0;
}
.yellow {
  background-color: #fffbe6;
  color: #d48806;
  border-radius: 4px;
  border: #fffbe6;
}
/deep/.el-dialog__body {
  padding: 10px 20px !important;
}
/deep/ .ant-table-small.ant-table-bordered .ant-table-fixed-right {
	border-left: none;
}
</style>