var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('ax-table',{ref:"tableBox",attrs:{"columns":_vm.columns,"toolActions":_vm.toolActions,"show-search":true,"searchForm":_vm.searchForm,"showToolBar":true,"row-selection":{
      selectedRowKeys: _vm.selectedRowKeys,
      onChange: _vm.onSelectChange,
    },"dataSourceApi":_vm.dataSourceApis,"dataSourceParams":_vm.dataSourceParams,"scroll":{ y: '55vh', x: '80vw' }},on:{"delect":_vm.del,"add":_vm.add,"action-column-click":_vm.actionColumnClick,"pop-confirm":_vm.popConfirm},scopedSlots:_vm._u([{key:"age",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(new Date().getFullYear() - parseInt(record.identityCard.substr(6, 4)))+" ")])}},{key:"cellName",fn:function({ record }){return _c('div',{staticClass:"operName"},[_vm._v(" "+_vm._s(record.cellName ? record.cellName : "--")+" ")])}},{key:"riskLevel_dictText",fn:function({ record }){return _c('div',{staticClass:"operName"},[_c('a-button',{class:{
          grey: record.riskLevel === '3',
          red: record.riskLevel === '1',
          yellow: record.riskLevel === '2',
        }},[_vm._v(_vm._s(record.riskLevel_dictText))])],1)}}])}),_c('add-interview',{ref:"interviewInfo",on:{"refsh":_vm.refsh}}),_c('watch-interview',{ref:"interviewInfoDetail"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }