<!-- 访客新增详情跳转页面-->
<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    width="60%"
    :close-on-click-modal="false"
    @close="closeModal"
  >
    <!-- 走访信息 -->
    <a-list bordered style="margin: 2% 0; border-radius: 10px">
      <a-list-item>
        <a-descriptions style="word-break: break-word;word-wrap: break-word;white-space: pre-wrap;">
          <a-descriptions-item label="现实状态" :span="3">
            {{ interviewInfoList.actualState_dictText }}
          </a-descriptions-item>
          <a-descriptions-item label="走访事项" :span="3">
            {{ interviewInfoList.interviewMatters }}
          </a-descriptions-item>
          <a-descriptions-item label="走访描述" :span="3">
            {{
              interviewInfoList.interviewDescription
                ? interviewInfoList.interviewDescription
                : "--"
            }}
          </a-descriptions-item>
          <a-descriptions-item label="走访图片" :span="3">
            <imagePreview ref="img1" :businessId="businessId"></imagePreview>
            </a-descriptions-item>
        </a-descriptions>
      </a-list-item>
      <div
        slot="header"
        style="
          display: flex;
          align-items: center;
          justify-content: space-between;
        "
        :headStyle="{ backgroundColor: '#FAFAFA' }"
      >
        <div style="display: flex; align-items: center; width: 30%">
          <img
            src="../../../../../assets/images/interviewInfo.png"
            style="width: 30px; height: 30px"
            alt=""
          />
          <div
            style="
              font-size: 0.08rem;
              font-weight: bold;
              margin-left: 2%;
              color: #2d3138;
            "
          >
            走访对象：{{ residentName }}
          </div>
        </div>
        <div style="font-size: 0.05rem; color: #7d8da6">
          走访时间：{{ interviewInfoList.interviewTime }}
        </div>
      </div>
    </a-list>
  </el-dialog>
</template>
<script>
import api from "./api";
import imagePreview from "./image-preview/imagePreview";
export default {
  components: {
    imagePreview,
  },
  data() {
    return {
      title: "",
      visible: false,
      businessId: "",
      disabled: false,
      flag: "",
      residentName: "",
      fileIds: [], //图片数组
      interviewInfoList: {}, //走访信息
    };
  },
  watch: {
    // 解决页面不刷新图片请求不发送问题
    businessId(newval) {
      console.log(newval, "412");
      this.$nextTick(() => {
        this.$refs.img1.getPicList(newval);
      });
    },
  },
  computed: {},
  mounted() {},
  methods: {
    openModal(record = {}) {
      this.visible = true;
      this.residentName = record.residentName;
      this.businessId = record.id;
      this.title = record.title;
      this.getInterviewInfo(record.id);
    },
    // 身份证号计算性别/年龄/出生日期
    calculateIDCardInfo(idCard) {
      let info = {
        gender: "",
        age: "",
        birthDate: "",
      };

      if (!idCard || (idCard.length !== 15 && idCard.length !== 18)) {
        return info;
      }

      let year =
        idCard.length === 15 ? "19" + idCard.substr(6, 2) : idCard.substr(6, 4);
      let month = idCard.substr(idCard.length === 15 ? 8 : 10, 2);
      let day = Number(idCard.substr(idCard.length === 15 ? 10 : 12, 2)) + 1;

      let genderCode =
        idCard.substr(idCard.length - 2, 1) % 2 === 0 ? "女" : "男";
      let birthday = new Date(year, parseInt(month) - 1, day); // 将月份减一
      let age = new Date().getFullYear() - birthday.getFullYear();
      info.gender = genderCode;
      info.age = age;
      info.birthDate = birthday.toISOString().slice(0, 10);
      this.interviewInfoList.age = info.age;
      return info;
    },
    // 取消
    closeModal() {
      this.visible = false;
      this.$emit("refsh");
    },
    // 根据ID查询图片
    async picVisitor(id) {
      const res = await api.picVisitor(id);
      console.log(res, "260");
    },
    // 根据id获取走访详情信息
    async getInterviewInfo(id) {
      const res = await api.getInterviewInfoById(id);
      this.interviewInfoList = res.data;
      console.log(res, "走访信息");
    },
  },
};
</script>

<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: end;
}

/deep/.el-dialog {
  border-radius: 20px !important;
}

/deep/.ant-descriptions-title {
  font-size: 0.08rem;
  border-left: 4px solid #556bff;
  padding-left: 0.5%;
}

/deep/.ant-descriptions-item-label {
  color: #7d8da6 !important;
}

/deep/.ant-descriptions-item-content {
  color: #2d3138;
}
/deep/.ant-list-bordered .ant-list-header {
  padding-left: 1% !important;
  background-color: #FAFAFA;
  border-radius: 10px 10px 0 0;
}
</style>